/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //Menu Button Active
        $('[data-toggle]').on('click', function() {
        $(this).toggleClass('active');
        });

        //Sub Menu Toggle
        if (window.matchMedia('(min-width: 980px)').matches) {
        $(document).ready(function() {
          $( '.menu-item-has-children' ).hover(
          function(){
            $(this).children('.sub-menu').slideDown(200);
          },
          function(){
            $(this).children('.sub-menu').slideUp(200);
          }
          );
        });
        }

        //Accordation
        $(document).ready(function() {
          $('.accord').click(function() {
          $('.accord').removeClass('active');
          $('.sub-menu').slideUp('normal');
          if($(this).next().is(':hidden') === true) {
          $(this).addClass('active');
          $(this).next().slideDown('normal');
          }
          });

          $('.accord').mouseover(function() {
          $(this).addClass('over');
          }).mouseout(function() {
          $(this).removeClass('over');
          });
        });

        //flexslider
        $(window).load(function() {
          $('.flexslider').flexslider({
            animation: "fade", // slide or fade
            controlsContainer: ".flex-container" // the container that holds the flexslider
          });
        });

        /**
         * Video carousal Slider
         */
        var VidSlider;
        var VidPager;

        function doThisVid(ele, old, newi) {
          VidSlider.goToSlide(newi);
          VidPager.goToSlide(newi);
        }
        VidSlider = $('.featured-video-slider').show().bxSlider({
          mode: 'fade',
          autoStart: true,
          pause: 7000,
          controls: false,
          video: true,
          adaptiveHeight: false,
          pagerCustom: '.video-pager',
          onSlideNext: doThisVid,
          onSlidePrev: doThisVid
        });
        VidPager = $('.video-pager').show().bxSlider({
          slideWidth: 250,
          minSlides: 2,
          maxSlides: 4,
          moveSlides: 1,
          slideMargin: 20,
          infiniteLoop: true,
          pager: false,
          onSlideNext: doThisVid,
          onSlidePrev: doThisVid
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
